export default {
  ["receive"]: "Receive",
  ["mining_pool"]: "Mining Pool",
  ["account"]: "Account",
  ["pool_data"]: 'Pool data',
  ["total_output"]: "Total Output",
  ["valid_node"]: "Valid Node",
  ["participant"]: "Participant",
  ["user_revenue"]: "User Revenue",
  ["activity"]: "Activity",
  ["mining"]: "Mining",
  ["liquidity"]: "liquidity mining income",
  ["user_output"]: "User Output",
  ["address"]: "Address",
  ["quantity"]: "Quantity",
  ["help_center"]: "Help Center",
  ["hep_center_tip"]: "hope it helps you",
  ["question1"]: "How can i join in？",
  ["answer1"]: "To participate in non-destructive and non-guaranteed liquidity mining, you need to pay an ETH miner's fee to receive the voucher, and an ETH wallet address only needs to be claimed once. Automatically open mining permissions after success.",
  ["question2"]: "How do i withdraw money？",
  ["answer2"]: "You can convert the coins produced daily into USDT, and then initiate a withdrawal. The USDT withdrawal will be automatically sent to the wallet address you added to the node, and other addresses are not supported.",
  ["question3"]: "How to calculate income？",
  ["answer3"]: "When you join successfully, the smart contract starts to calculate your address through the node and starts to calculate the income.",
  ["audit_report"]: "Audit Report",
  ["audit_report_tip"]: "we hava a secure audit report",
  ["partner"]: "Partner",
  ["partner_tip"]: "our business partner",
  ["my_account"]: "My account",
  ["mining_status"]: "Mining Status",
  ["wallet_balance"]: "Wallet Balance",
  ["exchangeable"]: "Exchangeable",
  ["withdrawable"]: "Withdrawable",
  ["share_dividends"]: "Share Dividends",
  ["my_level"]: "My Level",
  ["level"]: "Level",
  ["hash_rate"]: "Hash Rate",
  ["output_interest"]: "Output interest",
  ["output_interest_rate"]: "Output interest rate",
  ["output_interest_cycle"]: "Output interest cycle",
  ["level_details"]: "Level Details",
  ["pair_price"]: "Pair price",
  ["exchange"]: "Exchange",
  ["withdraw"]: "Withdraw",
  ["redeem_all"]: "Redeem all",
  ["total_balance"]: "Total balance",
  ["record"]: "Record",
  ["shared"]: "Shared",
  ["output"]: "Output",
  ["back"]: "Back",
  ["no-data"]: 'No data',
  ["received"]: "Received",
  ["transfer"]: "Transfer",
  ["share_friends"]: "Share friends",
  ["my_share_link"]: "My Share Link",
  ["copy"]: "Copy",
  ["share_tip"]: "Send your invitation link, friends join the node through your link, and you will get generous token rewards",
  ["join_tip"]: "You have joined",
  ["copySuccess"]: "Link has copyed",
  ["apply_mining_pool_rewards"]: "Apply mining pool rewards",
  ["standard"]: "Standard",
  ["required"]: "Required",
  ["countdown"]: "Countdown",
  ["host_day"]: "Day",
  ["reward"]: "Reward",
  ["i_know"]: "I know",
  ["popup_miniing_title"]: "Apply mining pool rewards",
  ["popup_miniing_conn1"]: "ERC-20 Smart Contract",
  ["popup_miniing_conn2"]: "The ERC-20  smart contract mining pool is about to start the next ETH sharing event. Apply to join now. After the event starts,liquidity providers can share multiple rewards",
  ["popup_miniing_btn"]: "Apply Rewards",
  ["recharge"]: 'Recharge',
  ["recharge_currency"]: "Deposit address",
  ["recharge_scan code transfer"]: "Save QR",
  ["recharge_amount"]: "Deposit amount",
  ["please enter"]: "Please Enter",
  ["recharge_upload"]: "Click to upload pictures",
  "confirm": "Confirm",
  ["rachange_link_coinbase"]: "Coinbase's official recharge channel",
  ["rachange_link_binance"]: "Binance's official recharge channel",
  ["rachange_link_huobi"]: "Huobi official recharge channel",
  "cancel": "Cancel",
  ["addressCopySuccess"]: "Address has copyed",
  ["recharge_record"]: "Recharge Record",
  ["all"]: "All",
  ["processing"]: "Pending",
  ["credited"]: "Success",
  ["fail"]: "Failed",
  ["amount"]: "Amount",
  ["state"]: "Status",
  ["time"]: "Time",
  ["record_tip"]: "Note",
  "activity-top-title": "ETH Airdrop Rewards",
"activity-top-big-title": "1 Million ETH Airdrop Rewards Coming",
"activity-top-tip": "Complete simple tasks on your DeFi yield project's mining pool node page to share in a huge airdrop reward.",
"activity-end": "Activity Ended",
"activity-join": "Join Activity",
}